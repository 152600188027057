<template>
  <h1 style="font-weight: normal; font-size: 18px;">
    <span style="font-size: 12px;">({{ index + 1 }}) </span>
    <strong v-text="value['@Value']"></strong>
    <small>
      <span v-if="value['Phonetic']"
        >&nbsp;[{{ value["Phonetic"]["#text"] || value["Phonetic"] }}]</span
      >
      <i v-if="value['@Type']">&nbsp;{{ value["@Type"] }}</i>
    </small>
    <small
      v-if="value.Reference && value.Reference['@Type'] === 'see'"
      style="margin-left: 8px;"
    >
      se: {{ value.Reference["@Value"] }}
    </small>
  </h1>

  <small v-if="value.Inflection">
    〈<inflection :inflection="value.Inflection" />〉
  </small>

  <small v-if="value.Abbreviate" style="display: block;">
    Förkortning: {{ value.Abbreviate["#text"] }}
  </small>

  <template v-if="value.Lexeme">
    <lexeme :value="value.Lexeme" v-if="value.Lexeme.length === undefined" />
    <lexeme
      :value="lexeme"
      v-else
      v-for="lexeme in value.Lexeme"
      :key="lexeme['@LexemeID']"
    />
  </template>
</template>

<script>
import Lexeme from "@/components/Lexeme";
import Inflection from "@/components/Inflection";

export default {
  name: "Lemma",

  components: {
    Lexeme,
    Inflection,
  },

  props: {
    index: {
      type: Number,
      required: true,
    },

    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
