<template>
  <div style="padding-left: 12px;">
    <definition :definition="value.Definition" :comment="value.Comment" />

    <ul v-if="value.Reference">
      <template v-if="value.Reference.length == undefined">
        <li v-if="value.Reference['@Type'] === 'compare'">
          Jämför: <strong>{{ value.Reference["@Value"] }}</strong>
        </li>
        <li v-else-if="value.Reference['@Type'] === 'antonym'">
          Motsats: <strong>{{ value.Reference["@Value"] }}</strong>
        </li>
      </template>
      <template v-else v-for="ref in value.Reference" :key="ref['@Value']">
        <li v-if="ref['@Type'] === 'compare'">
          Jämför: <strong>{{ ref["@Value"] }}</strong>
        </li>
        <li v-else-if="ref['@Type'] === 'antonym'">
          Motsats: <strong>{{ ref["@Value"] }}</strong>
        </li>
      </template>
    </ul>

    <div style="padding-left: 12px;" v-if="value.Compound">
      <label for="compound">Sammansättningar:</label>
      <ul id="compound">
        <template v-if="value.Compound.length === undefined">
          <li v-text="value.Compound['#text']"></li>
        </template>
        <li
          v-else
          v-for="compound in value.Compound"
          :key="compound['@ID']"
          v-text="compound['#text']"
        ></li>
      </ul>
    </div>

    <div style="padding-left: 12px;" v-if="value.Example">
      <label for="examples">Exempel:</label>
      <ul id="examples">
        <template v-if="value.Example.length === undefined">
          <li v-text="value.Example['#text']"></li>
        </template>
        <li
          v-else
          v-for="example in value.Example"
          :key="example['@ID']"
          v-text="example['#text']"
        ></li>
      </ul>
    </div>

    <template v-if="value.Cycle">
      <template v-if="value.Cycle.length === undefined">
        <lexeme :value="value.Cycle" />
      </template>
      <lexeme
        v-else
        :value="cycle"
        v-for="cycle in value.Cycle"
        :key="cycle['@CycleID']"
      />
    </template>

    <div style="padding-left: 12px;" v-if="value.Idiom">
      <label for="idioms">Uttryck:</label>
      <ul id="idioms">
        <idiom v-if="value.Idiom.length === undefined" :idiom="value.Idiom" />
        <idiom
          v-else
          :idiom="idiom"
          v-for="idiom in value.Idiom"
          :key="idiom['@ID']"
        />
      </ul>
    </div>

    <div style="padding-left: 12px;" v-if="value.Graminfo">
      <label for="graminfos">Konstruktioner:</label>
      <ul id="graminfos">
        <li
          v-if="value.Graminfo.length === undefined"
          v-text="value.Graminfo['#text']"
        ></li>
        <li
          v-else
          v-for="graminfo in value.Graminfo"
          :key="graminfo['#text']"
          v-text="graminfo['#text']"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import Definition from "@/components/Definition";
import Idiom from "@/components/Idiom";

export default {
  name: "Lexeme",

  components: { Definition, Idiom },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
