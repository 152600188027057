<template>
  <template v-for="(item, index) in inflections" :key="item['#text']">
    <span :title="item['@Form']">
      <span v-if="item['@Spec'] && item['@Form'] !== 'imperativ'"
        >{{ item["@Spec"] }}&nbsp;</span
      >
      <span>{{ item["#text"] }}</span>
      <span v-if="item['Alternate']"
        >&nbsp;<small>{{ item["Alternate"]["@Spec"] }}</small>
        {{ item["Alternate"]["#text"] }}
      </span>
    </span>
    <template v-if="item['@Form'] === 'imperativ'">
      {{ item["@Spec"] }}
    </template>
    <span v-if="index + 1 < inflections.length">,&nbsp;</span>
  </template>
</template>

<script>
export default {
  name: "Inflection",

  props: {
    inflection: [Array, Object, String],
  },

  computed: {
    inflections() {
      if (!this.inflection) return [];
      if (Array.isArray(this.inflection)) {
        return this.inflection.map((inflection) => {
          if (typeof inflection === "string") {
            return { "#text": inflection };
          }

          return inflection;
        });
      }

      return [this.inflection];
    },
  },
};
</script>
