<template>
  <form role="search">
    <label for="search-input">Hitta ord: </label>
    <input
      type="search"
      id="search-input"
      name="search"
      spellcheck="false"
      v-model="query"
    />
    <input value="Sök" @click.prevent="search()" type="submit" />
  </form>

  <hr />

  <template v-for="(word, wordIndex) in matches" :key="word.Lemma['@ID']">
    <lemma :value="word.Lemma" :index="wordIndex" />
  </template>

  <ul class="results">
    <li
      v-for="item in results"
      :key="item.refIndex"
      @click="
        query = item.item['@Sortkey'];
        search();
      "
      v-text="item.item.Lemma['@Value']"
    ></li>
  </ul>

  <footer
    style="text-align: right; padding: 8px; user-select: none; position: fixed; bottom: 0px; right: 0px;"
  >
    <small
      style="color: #333; font-size: 11px;"
      title='Källdatat är hämtat utan modifieringar från "Institutet för språk och
      folkminnen, Språkrådet" under licensen CC BY 2.5 SE'
    >
      Licens
    </small>
  </footer>
</template>

<script>
import Fuse from "fuse.js";

import Lemma from "@/components/Lemma";

export default {
  name: "App",

  components: {
    Lemma,
  },

  data() {
    return {
      query: "",
      fuse: null,

      matches: [],
      results: [],
    };
  },

  methods: {
    search(popState = false) {
      if (this.fuse === null) return;

      const query = this.query.trim().toLowerCase();

      if (!popState) {
        console.log("pushState", query);
        history.pushState({ query }, `Resultat: ${query}`, `?query=${query}`);
      }

      console.time("search");
      const results = this.fuse.search(this.query, { limit: 10 });
      console.timeEnd("search");

      this.matches = [];
      this.results = [];

      for (let i = 0; i < results.length; i++) {
        if (results[i].item["@Sortkey"] !== query) {
          if (
            !Array.isArray(results[i].item?.Lemma?.Index) ||
            !results[i].item.Lemma.Index.some(
              (index) => index["@Value"] === query
            )
          ) {
            continue;
          }
        }

        this.matches.push(results[i].item);
      }

      if (this.matches.length < 1) {
        this.results = results;
      }
    },
  },

  async created() {
    const data = await require("./assets/sv.json");

    this.fuse = new Fuse(data.Dictionary.Article, {
      keys: ["@Sortkey", "Lemma.@Value"],
      threshold: 0.8,
      distance: 10,
    });

    window.addEventListener("popstate", (event) => {
      if (event && event.state && event.state.query) {
        this.query = event.state.query;
        this.search(true);
      }
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

ul.results > li {
  text-decoration: underline;
  cursor: pointer;

  padding: 2px 0px;
}

form input {
  padding: 4px;
  font-size: 16px;
  margin: 0px 4px;
}
</style>
