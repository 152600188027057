<template>
  <h2 style="font-weight: normal; font-size: 16px;">
    <small
      v-for="comment in comments.filter((c) => c['@Type'] === 'general')"
      :key="comment['@ID']"
    >
      &lt;{{ comment["#text"] }}&gt;
    </small>
    <span v-if="definition" v-text="definition['#text']"></span>
    <small
      v-for="comment in comments.filter((c) => c['@Type'] === 'def')"
      :key="comment['@ID']"
    >
      {{ comment["#text"] }}
    </small>
    <small
      v-for="comment in comments.filter((c) => c['@Type'] === 'style')"
      :key="comment['@ID']"
    >
      &lt;{{ comment["#text"] }}&gt;
    </small>
  </h2>
</template>

<script>
export default {
  name: "Definition",

  props: {
    definition: Object,

    comment: [Object, Array],
  },

  computed: {
    comments() {
      if (!this.comment) return [];
      if (Array.isArray(this.comment)) return this.comment;
      return [this.comment];
    },
  },
};
</script>
