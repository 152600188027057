<template>
  <li>
    <template v-if="idiom.Definition">
      <strong>{{ idiom["#text"] }}</strong>
      &nbsp;&nbsp;{{ idiom.Definition["#text"] }}
    </template>
    <template v-else>
      {{ idiom["#text"] }}
    </template>
  </li>
</template>

<script>
export default {
  name: "Idiom",

  props: {
    idiom: Object,
  },
};
</script>
